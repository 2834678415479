@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap);
/*
Template Name: VIDOE - Video Streaming Website HTML Template
Author: Askbootstrap
Author URI: https://themeforest.net/user/askbootstrap
Version: 1.0
*/
/*
    -- Global Rules
    -- Customize Slick Slider
    -- Tag Cloud
    -- Custom Colors with higher specificity
*/
/* Global Rules */
@font-face {
    font-family: "Font Awesome 5 free";
    src: url(/static/media/fa-solid-900.2aae7c21.ttf);
}

:root {
    --input-padding-x: 0.75rem;
    --input-padding-y: 0.75rem;
}

html {
    position: relative;
    min-height: 100%;
}
body {
    background-color: #fafafa !important;
    color: #888 !important;
    font-size: 13px !important;
    height: 100%;
    font-family: "Open Sans", sans-serif !important;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
}
#wrapper {
    display: flex;
    /* Add bottom rule to fix double padding issue in react thin footer in single channel page */
    flex-direction: column;
}
#wrapper #content-wrapper {
    overflow-x: hidden;
    width: 100%;
}
#wrapper #content-wrapper .container-fluid {
    padding: 30px 30px 30px 255px;
}
.sidebar-toggled #wrapper #content-wrapper .container-fluid {
    padding: 30px 30px 30px 121px;
}

body.fixed-nav #content-wrapper {
    margin-top: 56px;
    padding-left: 90px;
}
body.fixed-nav.sidebar-toggled #content-wrapper {
    padding-left: 0;
}
@media (min-width: 768px) {
    body.fixed-nav #content-wrapper {
        padding-left: 225px;
    }
    body.fixed-nav.sidebar-toggled #content-wrapper {
        padding-left: 90px;
    }
}

.carousel-login-card {
    padding: 11px 0;
}

.smaller {
    font-size: 0.7rem;
}
.o-hidden {
    overflow: hidden !important;
}
.z-0 {
    z-index: 0;
}
.z-1 {
    z-index: 1;
}

/*
* These rules (.intro-page) are not part of the theme. 
* They are valid for separate info page 
* and should be removed in production app.
*/

/*.intro-page img {
    border-radius: 4px !important;
    box-shadow: 0 0 21px #ccc;
}
.intro-page p a {
    color: #000 !important;
    font-weight: bold;
    text-transform: uppercase;
}*/

.card-login {
    max-width: 25rem;
}
.card-register {
    max-width: 40rem;
}
.form-label-group {
    position: relative;
}
.form-label-group > input,
.form-label-group > label {
    padding: var(--input-padding-y) var(--input-padding-x);
    height: auto;
}
.form-label-group > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    /* Override default `<label>` margin */
    line-height: 1.5;
    color: #495057;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: all 0.1s ease-in-out;
}
.form-label-group input::placeholder {
    color: transparent;
}
.form-label-group input:not(:placeholder-shown) {
    padding-top: calc(
        var(--input-padding-y) + var(--input-padding-y) * (2 / 3)
    );
    padding-bottom: calc(var(--input-padding-y) / 3);
}
.form-label-group input:not(:placeholder-shown) ~ label {
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 12px;
    color: #777;
}

/* Customize Bootstrap */
.border-none {
    border: none !important;
}
.bg-primary,
.btn-primary,
.btn-outline-primary:hover,
.badge-primary {
    border-color: transparent !important;
    background: #ff516b;
    color: #fff !important;
    /* Old browsers */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #ff516b 0%, #826cfd 100%) !important;
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff516b', endColorstr='#826cfd',GradientType=1 ) !important;
    /* IE6-9 fallback on horizontal gradient */
}
.btn-outline-primary {
    border-color: #ff516b !important;
    color: #ff516b !important;
}
.text-primary {
    color: #ff516b !important;
}
.progress-bar {
    background-color: #ff516b !important;
}
.bg-success,
.btn-success,
.btn-outline-success:hover,
.badge-success {
    border-color: transparent !important;
    background: #4eda92;
    color: #fff !important;
    /* Old browsers */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #4eda92 1%, #56e0cb 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4eda92', endColorstr='#56e0cb',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */
}
.btn-outline-success {
    border-color: #4eda92 !important;
    color: #4eda92 !important;
}
.text-success {
    color: #4eda92 !important;
}
.bg-warning,
.btn-warning,
.btn-outline-warning:hover,
.badge-warning {
    border-color: transparent !important;
    background: #ffc107;

    /* Old browsers */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #ffc107 1%, #fffc07 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc107', endColorstr='#fffc07',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */
}
.btn-outline-warning {
    border-color: #ffc107;
    color: #ffc107;
}
.text-warning {
    color: #ffc107 !important;
}
.bg-danger,
.btn-danger,
.btn-outline-danger:hover,
.badge-danger {
    border-color: transparent !important;
    background: #ff253a;
    color: #fff !important;
    /* Old browsers */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #ff253a 0%, #ff8453 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff253a', endColorstr='#ff8453',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */
}
.btn-outline-danger {
    border-color: #ff253a !important;
    color: #ff253a !important;
}
.text-danger {
    color: #ff253a !important;
}
.bg-secondary,
.btn-secondary,
.btn-outline-secondary:hover,
.badge-secondary {
    border-color: transparent !important;
    background: #9ba8aa;
    /* Old browsers */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #9ba8aa 0%, #92b4d2 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9ba8aa', endColorstr='#92b4d2',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */
}
.btn-outline-secondary {
    border-color: #9ba8aa;
    color: #9ba8aa;
}
.text-secondary {
    color: #9ba8aa !important;
}
.bg-info,
.btn-info,
.btn-outline-info:hover,
.badge-info {
    border-color: transparent !important;
    background: #17a2b8;
    /* Old browsers */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #17a2b8 1%, #30def9 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#17a2b8', endColorstr='#30def9',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */
}
.btn-outline-info {
    border-color: #17a2b8;
    color: #17a2b8;
}
.text-info {
    color: #17a2b8 !important;
}
.bg-dark,
.btn-dark,
.btn-outline-dark:hover,
.badge-dark {
    border-color: transparent !important;
    background: #171b20;
    /* Old browsers */
    /* FF3.6-15 */
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #171b20 1%, #343a40 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#171b20', endColorstr='#343a40',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */
}
.btn-outline-dark {
    border-color: #171b20;
    color: #171b20;
}
.text-dark {
    color: #171b20 !important;
}
.btn-light {
    border: medium none;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #eceff0 !important;
    color: #888888 !important;
    text-decoration: none;
}
.form-control {
    background: #eceff0 none repeat scroll 0 0 !important;
    border-color: #dcdfdf !important;
    border-radius: 2px !important;
    font-size: 13px !important;
}
.form-control:focus {
    background-color: #fff !important;
}
.custom-select {
    background-color: #eceff0 !important;
    border-color: #dcdfdf !important;
    border-radius: 2px !important;
    font-size: 13px !important;
}
.custom-checkbox .custom-control-label::before {
    border-radius: 2px;
}
.category-checkbox .custom-control {
    margin: 0 0 6px !important;
}
label {
    font-size: 12px;
    font-weight: 600;
    margin: 0 0 5px;
}
a {
    color: #ff516b;
    text-decoration: none !important;
}
.btn {
    border-radius: 2px;
}
.btn-light {
    background: #f8f8f8 none repeat scroll 0 0;
    border-color: #d3d3d3;
    color: #828282 !important;
}
.dropdown-header {
    color: #333 !important;
    padding: 7px 13px !important;
}
.dropdown-menu {
    border-radius: 2px;
    margin: 0;
    border-color: #eff2f3;
}
.dropdown-menu .dropdown-item {
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    padding: 6px 14px !important;
}
.dropdown-divider {
    border-top: 1px solid #eff2f3;
}
a:hover,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #333;
}

.badge,
.alert {
    border-radius: 2px !important;
}

/* Video Card */
.video-block .mb-3 {
    margin-bottom: 30px !important;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    #wrapper #content-wrapper .container-fluid {
        padding-bottom: 15px !important;
        padding-left: 108px;
        padding-right: 15px !important;
        padding-top: 1rem !important;
    }
    .sidebar-toggled #wrapper #content-wrapper .container-fluid {
        padding-left: 15px;
    }
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    #wrapper #content-wrapper .container-fluid {
        padding-bottom: 15px !important;
        padding-left: 108px;
        padding-right: 15px !important;
        padding-top: 1rem !important;
    }

    .sidebar-toggled #wrapper #content-wrapper .container-fluid {
        padding-left: 15px;
    }

    .container {
        max-width: 100%;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    #wrapper #content-wrapper .container-fluid {
        padding-bottom: 15px !important;
        padding-left: 243px;
        padding-right: 15px !important;
        padding-top: 1rem !important;
    }
    .sidebar-toggled #wrapper #content-wrapper .container-fluid {
        padding-left: 105px;
    }
    .container {
        max-width: 100%;
    }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    #wrapper #content-wrapper .container-fluid {
        padding-bottom: 15px !important;
        padding-left: 243px;
        padding-right: 15px !important;
        padding-top: 1rem !important;
    }

    .sidebar-toggled #wrapper #content-wrapper .container-fluid {
        padding-left: 105px;
    }

    .container {
        max-width: 100%;
    }
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* Slick Slider */
/* Customize Slick Arrows*/
.custom-slick-btn {
    background: white;
    color: #dbdbdb;
    border: 0;
    outline: 0;
    margin: 0;
    padding: 0;
    line-height: 1rem;
    border-radius: 50px;
    opacity: 0.8;
    font-size: 2rem;
}
.custom-slick-btn:hover {
    color: #1c2224;
}

.custom-slick-next {
    position: absolute;
    top: 35%;
    right: 10px;
    display: block !important;
    transition: all 0.45s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}
.custom-slick-prev {
    position: absolute;
    top: 35%;
    left: 10px;
    display: block !important;
    transition: all 0.45s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    z-index: 800;
}

/* Customize Slick Dots*/
.slick-slide img {
    display: initial !important;
}
.slick-dots .slick-active button {
    border-radius: 50px;
    width: 16px !important;
    height: 16px !important;
    background: linear-gradient(135deg, #ff516b 0%, #826cfd 100%) !important;
}
.slick-dots button {
    border-radius: 50px;
    width: 16px !important;
    height: 16px !important;
    background: #dedede !important;
}
.slick-dots li {
    margin: 0 !important;
}
.slick-dots li.slick-active button:before {
    color: transparent !important;
}
.slick-dots li button:before {
    color: transparent !important;
    font-size: 13px !important;
}

/* Tag Cloud */
.single-video-info-content .tag-cloud-link {
    background: #ccc none repeat scroll 0 0;
    border-radius: 2px;
    color: #fff !important;
    display: inline-block;
    margin: 2px 0;
    padding: 4px 13px;
}
.single-video-info-content .tag-cloud-link:hover {
    background: #000 none repeat scroll 0 0;
    color: #fff !important;
}

/* Custom Colors with higher specificity*/

/* Custom text succes*/
.text-success-custom,
.single-video-left a.text-success-custom {
    color: #4eda92 !important;
}

a.text-success-custom:hover,
a.text-success-custom:active,
.single-video-left a.text-success-custom:hover,
.single-video-left a.text-success-custom:active {
    color: #4eda92 !important;
}

/* Custom text danger*/
.text-danger-custom {
    color: #ff253a !important;
}

/* CUstom text primary */
.text-primary-custom,
.category-item a,
.single-video-left a,
.right-action-link,
.terms a,
.osahan-close a,
.login-main-left a,
.sidebar-list-link {
    color: #ff516b !important;
}

/* Gray hover with higher specificity */
.category-item a:hover,
.video-title a:hover,
.single-video-left a:hover,
.single-video-left a:active,
.right-action-link:hover,
.right-action-link:active,
.single-video-info-content a:hover,
.single-video-info-content a:active {
    color: #333 !important;
}

/* Black hover with higher specificity */
.terms a:hover,
.terms a:active,
.osahan-close a:hover,
.osahan-close a:active,
.login-main-left a:hover,
.login-main-left a:active,
.sidebar-list-link:hover,
.sidebar-list-link:active {
    color: #000 !important;
}


.sticky-top {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 999;
}
.osahan-nav {
	box-shadow: 0 0 10px #f2f2f2 !important;
	padding: 0 !important;
}
.logo_favicon{
	display: flex;
	justify-content: space-between;
	align-items: center;
    align-self: center;
}
.log_title{
	font-size: 18px;
}
.osahan-navbar-search .form-control {
	background: #eceff0 none repeat scroll 0 0;
	border: medium none;
	font-size: 14px !important;
	max-width: 607px !important;
	width: 607px !important;
}
.osahan-navbar-search {
	margin-right: 84px !important;
}
.osahan-navbar-search .btn {
	background: #eceff0 none repeat scroll 0 0;
	border: medium none;
	font-size: 14px;
	padding: 0 18px;
}
.osahan-right-navbar .nav-link {
	padding: 17px 13px !important;
	position: relative;
}
.osahan-right-navbar {
	margin: 0 7px 0 0;
}
.navbar .dropdown-menu.show {
	right: 0;
	left: auto;
}
.user-dropdown-link img {
	border-radius: 50px;
	height: 30px;
	margin: -9px 2px -6px 0;
	width: 30px;
}

.navbar-nav .form-inline .input-group {
	width: 100%;
}
.navbar-nav .nav-item.dropdown .dropdown-toggle::after {
	width: 1rem;
	text-align: center;
	float: right;
	vertical-align: 0;
	border: 0;
	font-weight: 900;
	content: "\f105";
	font-family: "Font Awesome 5 Free";
}
.navbar-nav .nav-item.dropdown.show .dropdown-toggle::after {
	content: "\f107";
}
.navbar-nav .nav-item.dropdown.no-arrow .dropdown-toggle::after {
	display: none;
}
.navbar-nav .nav-item .nav-link:focus {
	outline: none;
}
.navbar-nav .nav-item .nav-link .badge {
	border: medium none !important;
	border-radius: 3px;
	font-size: 9px;
	font-weight: 700;
	height: 15px;
	line-height: 9px;
	min-width: 15px;
	position: absolute;
	text-align: center;
	top: 10px;
	right: 0;
}
.modal-content {
	border: medium none;
	border-radius: 2px;
}

@media (max-width: 575.98px) {
	.osahan-right-navbar .nav-link {
		font-size: 0;
		padding: 22px 5px !important;
	}
	.osahan-right-navbar .nav-link .fas,
	.osahan-right-navbar .nav-link svg {
		font-size: 12px;
	}
	#sidebarToggle {
		margin: 0 9px 0 0;
	}
	.navbar-brand img {
		vertical-align: top;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.osahan-right-navbar .nav-link {
		font-size: 0;
		padding: 22px 5px !important;
	}
	.osahan-right-navbar .nav-link .fas,
	.osahan-right-navbar .nav-link svg {
		font-size: 12px;
	}
	#sidebarToggle {
		margin: 0 9px 0 0;
	}
	.navbar-brand img {
		vertical-align: top;
	}
}
@media (min-width: 768px) and (max-width: 991.98px) {
	.osahan-right-navbar .nav-link {
		font-size: 0;
		padding: 22px 5px !important;
	}
	.osahan-right-navbar .nav-link .fas,
	.osahan-right-navbar .nav-link svg {
		font-size: 12px;
	}
	#sidebarToggle {
		margin: 0 9px 0 0;
	}
	.navbar-brand img {
		vertical-align: top;
	}
	.osahan-navbar-search .form-control {
		width: auto !important;
	}
	.osahan-navbar-search {
		margin-right: 15px !important;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.osahan-right-navbar .nav-link {
		font-size: 0;
		padding: 22px 5px !important;
	}
	.osahan-right-navbar .nav-link .fas,
	.osahan-right-navbar .nav-link svg {
		font-size: 12px;
	}
	#sidebarToggle {
		margin: 0 9px 0 0;
	}
	.navbar-brand img {
		vertical-align: top;
	}
	.osahan-navbar-search .form-control {
		width: auto !important;
	}
	.osahan-navbar-search {
		margin-right: 15px !important;
	}
}

@media (min-width: 768px) {
	.navbar-nav .form-inline .input-group {
		width: auto;
	}
}

.sidebar::-webkit-scrollbar {
	width: 6px;
	background-color: #f5f5f5;
}
.sidebar::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #555;
}

.channel-sidebar-list {
	border-top: 1px solid #ff516b;
	margin: 6px 0 0;
	padding: 7px 14px;
}
.channel-sidebar-list h6 {
	color: #fff !important;
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 15px;
	margin-top: 9px;
}
.channel-sidebar-list ul {
	list-style: outside none none;
	margin: 0;
	padding: 0;
}
.channel-sidebar-list ul li img {
	border-radius: 50px;
	height: 28px;
	margin: 0 14px 0 0;
	width: 28px;
}
.channel-sidebar-list li a {
	color: #fff;
	display: inline-block;
	overflow: hidden;
	padding: 6px 0;
	text-decoration: none;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}
.channel-sidebar-list li a .badge {
	float: right;
	margin: 7px 0 0;
	position: absolute;
	right: 20px;
}
.sidebar.toggled .nav-item .nav-link span {
	margin: 0;
}
.sidebar.toggled .channel-sidebar-list h6 {
	font-size: 11px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.sidebar.toggled .channel-sidebar-list {
	padding: 7px;
	text-align: center;
}
.sidebar.toggled .channel-sidebar-list li img {
	display: list-item;
	margin: auto auto 4px;
}
.sidebar.toggled .channel-sidebar-list li a {
	font-size: 10px;
	font-weight: 500;
}
.sidebar.toggled .channel-sidebar-list li .badge {
	display: none;
}

.sidebar {
	width: 90px !important;
	background: #ff516b;
	/* Old browsers */
	/* FF3.6-15 */
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(135deg, #ff516b 0%, #826cfd 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff516b', endColorstr='#826cfd',GradientType=1 );
	/* IE6-9 fallback on horizontal gradient */
	min-height: 100%;
	z-index: 9;
	padding: 2px 0;
	position: fixed;
	height: 100%;
}
.sidebar:hover {
	overflow: auto;
}

.sidebar .nav-item:last-child {
	margin-bottom: 1rem;
}
.sidebar .nav-item .nav-link {
	font-size: 14px;
	padding: 12px 14px !important;
	text-align: center;
	width: 90px;
	font-weight: 600;
}
.sidebar .nav-item .nav-link:hover,
.sidebar .nav-item.active .nav-link {
	background-color: rgba(255, 255, 255, 0.2) !important;
}
.sidebar .nav-item .nav-link .fas {
	color: #ffffff;
}
.sidebar .nav-item .nav-link span {
	display: inline;
	font-size: 14px;
	margin: 0 0 0 11px;
}
.sidebar .nav-item .dropdown-menu {
	position: absolute !important;
	transform: none !important;
	left: calc(90px + 0rem) !important;
	margin: 0;
}
.sidebar .dropdown-menu.show {
	margin-left: 1rem !important;
	margin-right: 1rem !important;
}

.sidebar .nav-item .dropdown-menu.dropup {
	bottom: 0;
	top: auto !important;
}
.sidebar .nav-item.dropdown .dropdown-toggle::after {
	display: none;
}
.sidebar .nav-item .nav-link {
	color: #ffffff;
}
.sidebar .nav-item .nav-link:active,
.sidebar .nav-item .nav-link:focus,
.sidebar .nav-item .nav-link:hover {
	color: rgba(255, 255, 255, 0.75);
}
.sidebar.toggled {
	width: 0 !important;
	overflow: hidden;
}

@media (max-width: 575.98px) {
	.sidebar .nav-item .nav-link {
		padding: 8px 14px !important;
	}
	.sidebar .nav-item .nav-link span {
		margin: 0 !important;
	}
	.sidebar .channel-sidebar-list h6 {
		font-size: 11px !important;
	}
	.sidebar .nav-item .nav-link span {
		display: block;
		font-size: 0.65rem;
	}
	.sidebar .channel-sidebar-list h6 {
		font-size: 11px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	.sidebar .channel-sidebar-list {
		padding: 7px;
		text-align: center;
	}
	.sidebar .channel-sidebar-list li img {
		display: list-item;
		margin: auto auto 4px;
	}
	.sidebar .channel-sidebar-list li a {
		font-size: 10px;
		font-weight: 500;
	}
	.sidebar .channel-sidebar-list li .badge {
		display: none;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.sidebar .nav-item .nav-link {
		padding: 8px 14px !important;
	}

	.sidebar .nav-item .nav-link span {
		margin: 0 !important;
	}
	.sidebar .channel-sidebar-list h6 {
		font-size: 11px !important;
	}
	.sidebar .nav-item .nav-link span {
		display: block;
		font-size: 0.65rem;
	}
	.sidebar .channel-sidebar-list h6 {
		font-size: 11px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	.sidebar .channel-sidebar-list {
		padding: 7px;
		text-align: center;
	}
	.sidebar .channel-sidebar-list li img {
		display: list-item;
		margin: auto auto 4px;
	}
	.sidebar .channel-sidebar-list li a {
		font-size: 10px;
		font-weight: 500;
	}
	.sidebar .channel-sidebar-list li .badge {
		display: none;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.sidebar .nav-item .nav-link {
		padding: 8px 14px !important;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.sidebar .nav-item .nav-link {
		padding: 8px 14px !important;
	}
}

/*///////////////////////////////////////////////////*/
@media (min-width: 768px) {
	.sidebar {
		height: 100%;
		position: fixed;
		width: 225px !important;
	}
	.sidebar:hover {
		overflow: auto;
	}
	.sidebar .nav-item .nav-link {
		display: block;
		width: 100%;
		text-align: left;
		padding: 1rem;
		width: 225px;
	}
	.sidebar .nav-item .nav-link span {
		display: inline;
		font-size: 13px;
		margin: 0 0 0 11px;
	}
	.sidebar .nav-item .dropdown-menu {
		position: static !important;
		margin: 0 1rem;
		top: 0;
	}
	.sidebar .nav-item.dropdown .dropdown-toggle::after {
		display: block;
	}
	.sidebar.toggled {
		overflow: visible;
		width: 90px !important;
	}
	.sidebar.toggled:hover {
		overflow-x: auto;
	}
	.sidebar.toggled .nav-item:last-child {
		margin-bottom: 1rem;
	}
	.sidebar.toggled .nav-item .nav-link {
		text-align: center;
		padding: 0.75rem 1rem;
		width: 90px;
	}
	.sidebar.toggled .nav-item .nav-link span {
		margin: 0;
	}
	.sidebar.toggled .nav-item .nav-link span {
		font-size: 0.65rem;
		display: block;
	}
	.sidebar.toggled .nav-item .dropdown-menu {
		position: absolute !important;
		transform: none !important;
		left: calc(90px + 0.5rem) !important;
		margin: 0;
	}
	.sidebar.toggled .nav-item .dropdown-menu.dropup {
		bottom: 0;
		top: auto !important;
	}
	.sidebar.toggled .nav-item.dropdown .dropdown-toggle::after {
		display: none;
	}
}

.sidebar.fixed-top {
	top: 56px;
	height: calc(100vh - 56px);
	overflow-y: auto;
}

.top-mobile-search {
	display: none;
}
.top-mobile-search {
	margin: -16px -15px 19px -18px;
}
.top-mobile-search input {
	border-radius: 0;
	box-shadow: none !important;
}
.top-mobile-search .btn {
	border-radius: 0;
	font-size: inherit;
}
@media (max-width: 575.98px) {
	.top-mobile-search {
		display: block;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.top-mobile-search {
		display: block;
	}
}

.no-after::after {
	display: none !important;
}

.main-title > h6 {
	margin: 0px 0 16px;
}
.top-category .main-title > h6 {
	margin-bottom: 10px;
}
.right-action-link span {
	background: #fff none repeat scroll 0 0;
	border-radius: 50px;
	box-shadow: 0 0 6px #ececec;
	display: inline-block;
	height: 20px;
	line-height: 19px;
	margin: 0 0 0 3px;
	text-align: center;
	width: 20px;
}

.category-item img {
	background: #fff none repeat scroll 0 0;
	border-radius: 50px;
	box-shadow: 0 0 2px #ccc;
	height: 60px;
	margin: 1px 0 8px;
	object-fit: scale-down;
	transform: scale(1);
	width: 60px;
}
.category-item:hover img {
	transform: scale(1.09);
	transition-duration: 0.4s;
}
.category-item {
	background: #fff none repeat scroll 0 0;
	border-radius: 2px;
	box-shadow: 0 0 6px #ececec;
	margin: 6px 2px;
	padding: 24px 12px;
	text-align: center;
}
.top-category .category-item {
	padding: 20px 11px;
}
.category-item h6 {
	font-size: 13px;
	margin: 5px 0 3px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.category-item p {
	font-size: 11px;
	font-weight: 500;
	margin: 0;
}

.video-card-image {
	border-radius: 2px;
	box-shadow: 0 0 11px #ececec;
	overflow: hidden;
	position: relative;
}
.time {
	background: #f5234a none repeat scroll 0 0;
	border-radius: 2px;
	bottom: 5px;
	color: #fff;
	font-size: 11px;
	font-weight: 500;
	opacity: 0.7;
	padding: 0 6px;
	position: absolute;
	right: 5px;
}
.video-card:hover .time {
	opacity: unset;
}
.video-card-image img {
	width: 100%;
}
.play-icon {
	opacity: 0;
	position: absolute;
}
.play-icon .fas,
.play-icon svg {
	bottom: 0;
	height: 40px;
	left: 0;
	margin: auto;
	position: absolute;
	right: 0;
	top: 0;
	width: 40px;
}
.video-card:hover .play-icon {
	background: #000 none repeat scroll 0 0;
	border-radius: 2px;
	bottom: 0;
	color: #fff;
	font-size: 35px;
	width: 100%;
	height: 100%;
	opacity: 0.4;
	position: center;
	right: 0;
	text-align: center;
	transition-duration: 0.4s;
}
.video-title a {
	color: #000 !important;
	font-weight: 600;
	text-decoration: none;
}
.video-title {
	margin-bottom: 5px;
}
.video-page {
	font-size: 11px;
}
.video-view {
	color: #acacac;
	font-size: 10px;
	font-weight: 500;
	margin: 2px 0 0;
}
.video-card {
	background: #fff none repeat scroll 0 0;
	border-radius: 2px;
	box-shadow: 0 0 11px #ececec;
	transition-duration: 0.4s;
}
.video-card-body {
	padding: 12px;
}
.video-card-image img.img-fixed-size {
	
}

/* Styles additionnels pour les images pour une taille fixe (optionnel) */
.video-card-image img.img-fixed-size {
	width: 150px; /* Largeur fixe pour toutes les images */
	height: 100px; /* Hauteur fixe pour toutes les images */
}

.video-card {
	border: 1px solid #ddd;
	border-radius: 4px;
	overflow: hidden;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
	margin-bottom: 10px;
}

.video-card-image {
	position: relative;
}

.video-card-image .play-icon {
	top: 30%;
	left: 80%;
	transform: translate(-50%, -50%);
	font-size: 24px;
	color: white;
	background: rgba(0, 0, 0, 0.5);
	border-radius: 50%;
	padding: 10px;
}

.video-card-body {
	padding: 15px;
}

.video-card-body .video-title {
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 10px;
}

.video-card-body .video-page {
	font-size: 14px;
	margin-bottom: 5px;
}

.video-card-body .video-view {
	font-size: 12px;
	color: #555;
}
.video-card {
	border: 1px solid #ddd;
	border-radius: 4px;
	overflow: hidden;
	transition: box-shadow 0.3s;
  }
  
  .video-card:hover {
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .video-card-image {
	position: relative;
  }
  
  .video-card-image .play-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 2rem;
	color: #fff;
  }
  
  .video-card-image img {
	width: 100%;
	height: auto;
  }
  
  .video-card-image .time {
	position: absolute;
	bottom: 8px;
	right: 8px;
	background-color: rgba(0, 0, 0, 0.7);
	color: #fff;
	padding: 2px 4px;
	border-radius: 2px;
	font-size: 0.75rem;
  }
  
  .video-card-body {
	padding: 16px;
  }
  
  .video-title a {
	font-size: 1rem;
	font-weight: bold;
	color: #000;
	text-decoration: none;
  }
  
  .video-title a:hover {
	text-decoration: underline;
  }
  
  .video-page {
	margin: 8px 0;
	font-size: 0.875rem;
  }
  
  .text-success-custom {
	color: green;
  }
  
  .text-danger-custom {
	color: red;
  }
  
  .video-view {
	font-size: 0.875rem;
	color: #555;
  }
  
.channels-card {
	background: #fff none repeat scroll 0 0;
	border-radius: 2px;
	box-shadow: 0 0 11px #ececec;
	padding: 24px 12px;
	position: relative;
	text-align: center;
	transition-duration: 0.4s;
}
.channels-card::after {
	background: #dcdcdc none repeat scroll 0 0;
	border-radius: 0 0 5px 6px;
	bottom: -4px;
	content: "";
	height: 7px;
	left: 15px;
	position: absolute;
	right: 15px;
	z-index: -1;
}
.channels-card:hover::after {
	background: #cccccc none repeat scroll 0 0;
}
.channels-title a {
	color: #000 !important;
	font-weight: 500;
	text-decoration: none;
}
.channels-view {
	color: #acacac;
}
.channels-card-image .btn {
	font-size: 12px;
	padding: 3px 10px;
}
.channels-card-image .btn strong {
	margin: 0 0 0 5px;
}
.channels-card-image img {
	background: #fff none repeat scroll 0 0;
	border-radius: 50px;
	box-shadow: 0 0 2px #ccc;
	height: 80px;
	margin: 1px 0 8px;
	object-fit: scale-down;
	transform: scale(1);
	width: 80px;
}
.channels-card-image-btn {
	margin: 3px 0 7px;
}

footer.sticky-footer {
	background-color: #e9ecef;
	padding: 15px;
	margin-left: 225px;
}
footer.sticky-footer .copyright {
	line-height: 1;
	font-size: 0.8rem;
}
body.sidebar-toggled footer.sticky-footer {
	width: 100%;
}
.app img {
	background: #231f20 none repeat scroll 0 0;
	border-radius: 3px;
	height: 38px;
	margin: 5px 0 0;
	object-fit: scale-down;
	padding: 3px 4px;
}

.footer-list .app img {
	height: 28px;
}
.footer-list {
	padding: 20px 0;
}
.footer-list ul {
	margin: 0;
	padding: 0;
}
.footer-list ul li {
	list-style: outside none none;
}
.footer-list ul li a {
	color: #666;
	line-height: 23.9px;
}
.footer-list .mb-0 {
	margin: 7px 0 0;
}
.footer-logo {
	margin: 0 0 51px !important;
}

@media (max-width: 575.98px) {
	footer {
		padding: 15px 0 !important;
		text-align: center;
	}
	footer .text-right {
		text-align: center !important;
	}
	footer.sticky-footer {
		margin-left: 90px;
	}
	.sidebar-toggled footer.sticky-footer {
		margin: 0;
	}
	.footer-logo {
		margin: 0 0 13px !important;
	}
	.footer-list h6 {
		margin: 23px 0 10px !important;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	footer {
		padding: 15px 0 !important;
		text-align: left;
	}
	footer .text-right {
		text-align: right !important;
	}
	footer.sticky-footer {
		margin-left: 90px;
	}
	.sidebar-toggled footer.sticky-footer {
		margin: 0;
	}

	.footer-logo {
		margin: 0 0 13px !important;
	}
	.footer-list h6 {
		margin: 23px 0 10px !important;
	}
}

/*/////////////////////////////////////////////*/

@media (min-width: 768px) {
	body.sidebar-toggled footer.sticky-footer {
		width: calc(100% - 90px);
		margin-left: 90px;
	}
}

.pagination .page-item .page-link {
	background: transparent none repeat scroll 0 0;
	border: medium none;
	color: #000;
	line-height: 20px;
}
.pagination .page-item.active .page-link {
	background: #ff516b none repeat scroll 0 0;
	border-radius: 2px;
	color: #fff;
	height: 30px;
	margin: 0 7px;
	text-align: center;
	width: 30px;
}
.pagination .page-item.disabled .page-link {
	opacity: 0.5;
}

.single-channel-page {
	padding: 0px 0px 0px 225px;
}

.single-channel-page .container-fluid {
	padding: 30px 30px 30px !important;
}

.sidebar-toggled .single-channel-page {
	padding: 0px 0px 0px 90px;
}

/* Single Channel */
.single-channel-image {
	position: relative;
}
.single-channel-nav .navbar {
	padding: 0;
}
.single-channel-nav {
	background: #fff none repeat scroll 0 0 !important;
	box-shadow: 0 0 11px #ececec;
	padding: 0rem 30px 0;
}
.channel-brand {
	color: #000 important !important;
	font-size: 16px !important;
	font-weight: bold;
}
.single-channel-nav .nav-link {
	border-bottom: 2px solid transparent;
	margin: 0 12px;
	padding: 14px 0 !important;
}
.single-channel-nav .nav-item.active .nav-link,
.single-channel-nav .nav-item .nav-link:hover,
.single-channel-nav .nav-item.active .nav-link:active {
	border-color: #ff516b !important;
	color: #ff516b !important;
}
.single-channel-nav .navbar-nav {
	margin: 0 0 0 40px;
}
.channel-profile {
	bottom: 0;
	left: 0;
	padding: 1rem 30px;
	position: absolute;
	right: 0;
}
.single-channel-image .img-fluid {
	width: 100%;
}
.channel-profile-img {
	background: #fff none repeat scroll 0 0;
	border: 6px solid #fff;
	border-radius: 50px;
	height: 90px;
	width: 90px;
}
.social {
	color: #fff;
	float: right;
	margin: 62px 0 0;
}
.social a {
	background: #000 none repeat scroll 0 0;
	border-radius: 2px;
	color: #fff;
	font-size: 12px;
	font-weight: 600;
	padding: 5px 10px;
}

@media (max-width: 575.98px) {
	.single-channel-page {
		padding: 0 0 0 90px !important;
	}
	.sidebar-toggled .single-channel-page {
		padding: 0 !important;
	}
	.single-channel-page .container-fluid {
		padding: 30px 15px 15px !important;
	}
	.single-channel-nav {
		padding: 15px;
	}
	.channel-profile {
		padding: 10px;
		position: relative;
		text-align: center;
	}
	.social {
		display: none;
	}
	.navbar-toggler {
		padding: 1px 3px;
	}
	.single-channel-nav .navbar-nav {
		margin: 0;
	}
	.single-channel-nav .nav-link {
		margin: 0;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.single-channel-page {
		padding: 0 0 0 90px !important;
	}
	.sidebar-toggled .single-channel-page {
		padding: 0 !important;
	}
	.single-channel-page .container-fluid {
		padding: 30px 15px 15px !important;
	}
	.single-channel-nav {
		padding: 15px;
	}
	.channel-profile {
		padding: 10px;
		position: relative;
		text-align: center;
	}
	.social {
		display: none;
	}
	.navbar-toggler {
		padding: 1px 3px;
	}
	.single-channel-nav .navbar-nav {
		margin: 0;
	}
	.single-channel-nav .nav-link {
		margin: 0;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.single-channel-page {
		padding: 0 0 0 225px !important;
	}
	.sidebar-toggled .single-channel-page {
		padding: 0 0 0 90px !important;
	}
	.single-channel-page .container-fluid {
		padding: 30px 15px 15px !important;
	}
	.single-channel-nav {
		padding: 15px;
	}
	.channel-profile {
		padding: 15px;
	}
	.social {
		display: none;
	}
	.navbar-toggler {
		padding: 1px 3px;
	}
	.single-channel-nav .navbar-nav {
		margin: 0;
	}
	.single-channel-nav .nav-link {
		margin: 0;
	}
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.single-channel-page {
		padding: 0 0 0 225px !important;
	}
	.sidebar-toggled .single-channel-page {
		padding: 0 0 0 90px !important;
	}
	.single-channel-page .container-fluid {
		padding: 30px 15px 15px !important;
	}
	.single-channel-nav {
		padding: 0 15px;
	}
	.channel-profile {
		padding: 15px;
	}
	.social {
		display: none;
	}
	.navbar-toggler {
		padding: 1px 3px;
	}
	.single-channel-nav .navbar-nav {
		margin: 0 0 0 20px;
	}
	.single-channel-nav .nav-link {
		margin: 0 7px;
	}
	.single-channel-nav .form-control,
	.single-channel-nav .btn-outline-success {
		display: none;
	}
}

.adblock {
	background: #eceff0 none repeat scroll 0 0;
	border-radius: 2px;
	margin-bottom: 1rem;
	padding: 27px 10px;
	text-align: center;
	width: 100%;
}
.video-card-list .video-card-image {
	float: left;
	height: 77px;
	margin: 0 12px 0 0;
	width: 122px;
}
.video-card-list .video-card-body {
	padding: 0;
}
.video-card-list {
	background: transparent none repeat scroll 0 0;
	box-shadow: none;
	display: inline-block;
	margin-bottom: 1rem;
	width: 100%;
}
.video-card-list .video-card-body .video-title a {
	font-size: 13px;
}
.video-card-list .video-card-body .video-title {
	line-height: 17px;
}
.video-card-list .video-card-body .right-action {
	opacity: 0;
}
.video-card-list:hover .video-card-body .right-action {
	opacity: unset;
}

/* Comment Box */
.single-video-comment-tabs .nav.nav-tabs {
	border-bottom: 2px solid #e7e9ee;
	margin: -15px -15px 15px;
	padding: 0 15px;
}
.single-video-comment-tabs .nav.nav-tabs .nav-link {
	border-color: #b7b9be !important;
	border-radius: 2px !important;
	border-style: none none solid;
	border-width: 0 0 2px;
	color: #b7b9be !important;
	font-weight: 600;
	margin: 0 !important;
	padding: 16px 17px !important;
	text-transform: uppercase;
}
.single-video-comment-tabs .nav.nav-tabs .nav-link.active,
.single-video-comment-tabs .nav.nav-tabs .nav-link:hover {
	border-color: #000 !important;
	color: #000 !important;
}

  
.single-video-comment-tabs .nav-tabs .nav-item {
	margin-bottom: -2px;
}

/* Comment Media, Custom Media */
.reviews-members {
	border-bottom: 1px solid #dcdcdc;
	margin: 0 -15px;
	padding: 15px;
}
.reviews-members-header .text-black {
	color: #000 !important;
	font-weight: 600;
}
.reviews-members-header .text-gray {
	color: #7c7c7c !important;
	font-size: 11px;
	font-weight: 700;
	margin: 0 6px;
}
.reviews-members-header {
	margin-bottom: 11px;
}
.reviews-members-body p {
	color: #7b7b7b;
	font-size: 13px;
}
.reviews-members-footer {
	display: inline-block;
	width: 100%;
}
.reviews-members .media img {
	border-radius: 50px;
	height: 51px;
	width: 51px;
}
.reviews-members:last-child {
	border: medium none;
	padding-bottom: 5px;
}

/* UsersWhoLiked */
.total-like-user-main img {
	border: 2px solid #fff;
	box-shadow: 0 0 2px #ccc;
	height: 25px !important;
	margin: 0 0 0 -11px;
	width: 25px !important;
}

.total-like {
	border: 1px solid #ff516b;
	border-radius: 3px;
	color: #ff516b !important;
	font-size: 11px;
	padding: 4px 10px;
	vertical-align: sub;
}
.single-video-left .total-like:hover {
	background: #ff516b none repeat scroll 0 0;
	border: 1px solid #ff516b;
	border-radius: 3px;
	color: #ffffff !important;
	font-size: 11px;
	padding: 4px 10px;
	vertical-align: sub;
}
.total-like-user-main.ml-2 {
	margin: 0 9px 0 17px !important;
}

/**/
.video-slider-right-list {
	background: #fff none repeat scroll 0 0;
	border-radius: 2px;
	box-shadow: 0 0 11px #ececec;
	height: 314px;
	overflow: auto;
}
.video-slider-right-list .video-card-list .video-card-image {
	height: 59px;
	width: 95px;
}
.video-slider-right-list .video-card-image img {
	object-fit: scale-down;
}
.video-slider-right-list .video-title a {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.video-slider-right-list .video-title {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.video-slider-right-list .video-card-list {
	cursor: pointer;
	margin-bottom: 1px;
	padding: 9px;
}
.video-slider-right-list .video-card-list:hover,
.video-slider-right-list .video-card-list.active {
	background: #eceff0 none repeat scroll 0 0;
}
.video-slider-right-list::-webkit-scrollbar {
	width: 6px;
	background-color: #f5f5f5;
}
.video-slider-right-list::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #555;
}

/**/

.box {
	padding: 15px;
	background: #fff none repeat scroll 0 0;
	border-radius: 2px;
	box-shadow: 0 0 11px #ececec;
	transition-duration: 0.4s;
}
.single-video-title h2 {
	font-size: 18px;
}
.single-video-title h2 a {
	color: #333 !important;
}
.single-video-author img {
	border-radius: 50px;
	float: left;
	height: 38px;
	margin: 0 13px 0 0;
	width: 38px;
}
.single-video-author p {
	margin: 0;
	padding: 0;
}
.single-video-info-content h6 {
	font-size: 15px;
	font-weight: 600;
}
/* VideoPage.css */

.video-player-wrapper {
	position: relative;
	padding-top: 56.25%; /* 16:9 aspect ratio */
  }
  
  .video-player-wrapper video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  }
  /* VideoPage.css */

.video-player-wrapper {
	position: relative;
	padding-top: 56.25%; /* 16:9 aspect ratio */
  }
  
  .video-player-wrapper video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  }
  
  @media (max-width: 768px) {
	.video-block-right-list .single-video,
	.video-block .single-video {
	  margin-bottom: 20px;
	}
  }
  
  @media (min-width: 768px) {
	.video-block-right-list .single-video,
	.video-block .single-video {
	  margin-bottom: 0;
	}
  }
  
  .video-block-right-list .player-wrapper,
  .video-block .player-wrapper {
	padding-bottom: 56.25%; /* 16:9 aspect ratio */
	position: relative;
	height: 0;
  }
  
  .video-block-right-list .player-wrapper iframe,
  .video-block .player-wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  }
  
  .section-padding {
	padding: 20px 0;
  }
  
  
  @media (max-width: 768px) {
	.video-block-right-list .single-video,
	.video-block .single-video {
	  margin-bottom: 20px;
	}
  }
  
  @media (min-width: 768px) {
	.video-block-right-list .single-video,
	.video-block .single-video {
	  margin-bottom: 0;
	}
  }
  
  .video-block-right-list .player-wrapper,
  .video-block .player-wrapper {
	padding-bottom: 56.25%; /* 16:9 aspect ratio */
	position: relative;
	height: 0;
  }
  
  .video-block-right-list .player-wrapper iframe,
  .video-block .player-wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  }
  
  .section-padding {
	padding: 20px 0;
  }
  

@media (max-width: 575.98px) {
	.single-video-author .float-right {
		float: none !important;
		margin: 0 0 14px;
	}
	.single-video-author {
		text-align: center;
	}
	.single-video-author img {
		float: none;
		margin: 0 0 5px;
	}
	.video-card-image img {
		width: 100%;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.video-card-image img {
		width: 100%;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.video-card-image img {
		width: 100%;
	}
}
@media (min-width: 992px) and (max-width: 1199.98px) {
	.video-card-image img {
		width: 100%;
	}
}

.upload-video .text-primary {
	color: #ff516b !important;
}
.imgplace {
	background-color: #8781bd;
	border-radius: 3px;
	height: 94px;
	width: 100%;
}
.osahan-title {
	color: #000;
	font-weight: 600;
}
.osahan-size {
	font-size: 11px;
}
.osahan-progress .progress {
	border-radius: 2px;
	height: 8px;
	margin: 14px 0;
}
.osahan-close {
	position: absolute;
	right: 18px;
	top: 0;
}
.osahan-desc {
	font-size: 12px;
	opacity: 0.7;
}

@media (max-width: 575.98px) {
	.imgplace {
		margin-bottom: 15px;
	}
	.osahan-title {
		padding: 0 25px 5px 0;
	}
}

@media (min-width: 576px) and (max-width: 767.98px) {
	.imgplace {
		margin-bottom: 15px;
	}
	.osahan-title {
		padding: 0 25px 5px 0;
	}
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.imgplace {
		margin-bottom: 15px;
	}
	.osahan-title {
		padding: 0 25px 5px 0;
	}
}

.video-close {
	color: #fff !important;
	font-size: 18px;
	position: absolute;
	right: 6px;
	z-index: 990;
}
.history-video .progress {
	border-radius: 2px;
	font-size: 9px;
	font-weight: 700;
	height: 13px;
	margin: 12px 14px -4px 13px;
}

.upload-video .fas,
.upload-video svg {
	font-size: 200px;
}

.media-body > .media {
	margin-bottom: 0 !important;
	margin-top: 2rem !important;
}

.media:last-child:not(.media-body > .media) {
	margin-bottom: 0 !important;
}

.card {
	background: #fff none repeat scroll 0 0;
	border-radius: 2px !important;
	box-shadow: 0 0 11px #ececec !important;
	border: none !important;
}
.sidebar-card-list li a {
	border-bottom: 1px solid #efefef;
	display: inline-block;
	padding: 11px 0;
	width: 100%;
}
.sidebar-card-list li:last-child a {
	border: medium none;
	padding-bottom: 0;
}
.tag-cloud-link {
	background: #ccc none repeat scroll 0 0;
	border-radius: 2px;
	color: #fff !important;
	display: inline-block;
	margin: 2px 0;
	padding: 4px 13px;
}
.tag-cloud-link:hover {
	background: #000 none repeat scroll 0 0;
	color: #fff;
}
.blog .card-title a {
	color: #222 !important;
}
.blog .card-title {
	margin: 0 0 7px;
}
.entry-meta {
	margin-bottom: 13px;
}
.blog .card-body a {
	color: #ff516b;
}
.entry-meta a {
	color: #9c9c9c !important;
}
.entry-meta .fas,
.entry-meta span {
	background: #acacac none repeat scroll 0 0;
	border-radius: 12px;
	color: #fff;
	display: inline-block;
	font-size: 9px;
	height: 19px;
	line-height: 19px;
	text-align: center;
	width: 19px;
}

.sidebar-card .input-group .btn {
	font-size: 13px;
}
.sidebar-card .sidebar-card-list {
	margin: 0;
	padding: 0;
}
.sidebar-card .sidebar-card-list li {
	list-style: outside none none;
}
.sidebar-card .sidebar-card-list li i,
.sidebar-card .sidebar-card-list li svg {
	font-size: 10px;
	margin: 0 8px 0 0;
}

.gallery.mb-4 img {
	width: 100%;
}
.reviews-card .rounded {
	border-radius: 50px !important;
	width: 54px;
}
.star-rating.float-right {
	font-size: 13px;
}

.login-main-wrapper {
	background: #ff516b;
	/* Old browsers */
	/* FF3.6-15 */
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(135deg, #ff516b 0%, #826cfd 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff516b', endColorstr='#826cfd',GradientType=1 );
	/* IE6-9 fallback on horizontal gradient */
	height: calc(100vh - 0rem);
}
.full-height {
	height: calc(100vh - 0rem);
}
.login-main-left {
	margin: auto;
	max-width: 335px;
}
.login-main-right.bg-white {
	border-radius: 2px;
	margin: auto;
	max-width: 561px;
}
.carousel-login-card h5 {
	font-size: 18px;
}
.login-main-wrapper .form-control {
	background: transparent none repeat scroll 0 0 !important;
}
.login-main-right .owl-theme .owl-controls .owl-page span {
	background: #dcdcdc;
	border-radius: 50px;
	height: 16px;
	margin: 0 2px;
	opacity: unset;
	width: 16px;
}
.btn-lg {
	font-size: 15px !important;
	padding: 12px 16px !important;
}
.login-main-right .owl-theme .owl-controls .owl-page.active span,
.login-main-right .owl-theme .owl-controls .owl-page span:hover {
	background: #ff516b;
	/* Old browsers */
	/* FF3.6-15 */
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(135deg, #ff516b 0%, #826cfd 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff516b', endColorstr='#826cfd',GradientType=1 );
	/* IE6-9 fallback on horizontal gradient */
}

/* InfoCard */
.card-body-icon {
    position: absolute;
    z-index: 0;
    top: -1.25rem;
    right: -1rem;
    opacity: 0.4;
    font-size: 5rem;
    transform: rotate(15deg);
}

.card.bg-primary {
    background: #007bff !important;
}

@media (min-width: 576px) {
    .card-columns {
        column-count: 1;
    }
}
@media (min-width: 768px) {
    .card-columns {
        column-count: 2;
    }
}
@media (min-width: 1200px) {
    .card-columns {
        column-count: 2;
    }
}

.scroll-to-top {
	background: rgba(52, 58, 64, 0.5) none repeat scroll 0 0;
	border-radius: 70px !important;
	bottom: 86px;
	color: #fff;
	/*display: none;*/
	height: 50px;
	line-height: 46px;
	position: fixed;
	right: 15px;
	text-align: center;
	width: 50px;
	z-index: 998;
	border: 0;
}
.scroll-to-top:focus,
.scroll-to-top:hover {
	color: white;
	outline: 0;
	border: 0;
}
.scroll-to-top:hover {
	background: #343a40;
}
.scroll-to-top i {
	font-weight: 800;
}

