.video-card-image {
	border-radius: 2px;
	box-shadow: 0 0 11px #ececec;
	overflow: hidden;
	position: relative;
}
.time {
	background: #f5234a none repeat scroll 0 0;
	border-radius: 2px;
	bottom: 5px;
	color: #fff;
	font-size: 11px;
	font-weight: 500;
	opacity: 0.7;
	padding: 0 6px;
	position: absolute;
	right: 5px;
}
.video-card:hover .time {
	opacity: unset;
}
.video-card-image img {
	width: 100%;
}
.play-icon {
	opacity: 0;
	position: absolute;
}
.play-icon .fas,
.play-icon svg {
	bottom: 0;
	height: 40px;
	left: 0;
	margin: auto;
	position: absolute;
	right: 0;
	top: 0;
	width: 40px;
}
.video-card:hover .play-icon {
	background: #000 none repeat scroll 0 0;
	border-radius: 2px;
	bottom: 0;
	color: #fff;
	font-size: 35px;
	width: 100%;
	height: 100%;
	opacity: 0.4;
	position: center;
	right: 0;
	text-align: center;
	transition-duration: 0.4s;
}
.video-title a {
	color: #000 !important;
	font-weight: 600;
	text-decoration: none;
}
.video-title {
	margin-bottom: 5px;
}
.video-page {
	font-size: 11px;
}
.video-view {
	color: #acacac;
	font-size: 10px;
	font-weight: 500;
	margin: 2px 0 0;
}
.video-card {
	background: #fff none repeat scroll 0 0;
	border-radius: 2px;
	box-shadow: 0 0 11px #ececec;
	transition-duration: 0.4s;
}
.video-card-body {
	padding: 12px;
}
.video-card-image img.img-fixed-size {
	
}

/* Styles additionnels pour les images pour une taille fixe (optionnel) */
.video-card-image img.img-fixed-size {
	width: 150px; /* Largeur fixe pour toutes les images */
	height: 100px; /* Hauteur fixe pour toutes les images */
}

.video-card {
	border: 1px solid #ddd;
	border-radius: 4px;
	overflow: hidden;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
	margin-bottom: 10px;
}

.video-card-image {
	position: relative;
}

.video-card-image .play-icon {
	top: 30%;
	left: 80%;
	transform: translate(-50%, -50%);
	font-size: 24px;
	color: white;
	background: rgba(0, 0, 0, 0.5);
	border-radius: 50%;
	padding: 10px;
}

.video-card-body {
	padding: 15px;
}

.video-card-body .video-title {
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 10px;
}

.video-card-body .video-page {
	font-size: 14px;
	margin-bottom: 5px;
}

.video-card-body .video-view {
	font-size: 12px;
	color: #555;
}
.video-card {
	border: 1px solid #ddd;
	border-radius: 4px;
	overflow: hidden;
	transition: box-shadow 0.3s;
  }
  
  .video-card:hover {
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .video-card-image {
	position: relative;
  }
  
  .video-card-image .play-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 2rem;
	color: #fff;
  }
  
  .video-card-image img {
	width: 100%;
	height: auto;
  }
  
  .video-card-image .time {
	position: absolute;
	bottom: 8px;
	right: 8px;
	background-color: rgba(0, 0, 0, 0.7);
	color: #fff;
	padding: 2px 4px;
	border-radius: 2px;
	font-size: 0.75rem;
  }
  
  .video-card-body {
	padding: 16px;
  }
  
  .video-title a {
	font-size: 1rem;
	font-weight: bold;
	color: #000;
	text-decoration: none;
  }
  
  .video-title a:hover {
	text-decoration: underline;
  }
  
  .video-page {
	margin: 8px 0;
	font-size: 0.875rem;
  }
  
  .text-success-custom {
	color: green;
  }
  
  .text-danger-custom {
	color: red;
  }
  
  .video-view {
	font-size: 0.875rem;
	color: #555;
  }
  