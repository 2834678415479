.pagination .page-item .page-link {
	background: transparent none repeat scroll 0 0;
	border: medium none;
	color: #000;
	line-height: 20px;
}
.pagination .page-item.active .page-link {
	background: #ff516b none repeat scroll 0 0;
	border-radius: 2px;
	color: #fff;
	height: 30px;
	margin: 0 7px;
	text-align: center;
	width: 30px;
}
.pagination .page-item.disabled .page-link {
	opacity: 0.5;
}
